#news .header .search {
  width: 60%;
}

#news .header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 2;
  top: 0;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
}

#news .bottom-fixed {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  bottom: 0;
  background-color: white;
  z-index: 2;
  border-top: 1pt solid #ef8834;
}

#news .header object {
  width: 25px;
}

#news .header .icon {
  display: flex;
  align-items: center;
}

#news .header .icon object {
  pointer-events: none;
}

#news .header .icon:hover {
  cursor: pointer;
}

#news .header .explore p {
  display: inline-block;
  color: #000000;
  margin: 0;
  font-weight: 500;
  font-size: 13pt;
}

#news .header .explore:hover {
  cursor: pointer;
}

#news .empty {
  margin-top: 40vh;
}

#news .search-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1em;
}

.news-search {
  height: 5vh;
  border-radius: 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000014;
  color: #1d1d1d;
  background-color: white;
  display: flex;
}

.news-search input {
  width: 90%;
  padding: 0.5em 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
  padding-left: 3vw;
}

.news-search svg {
  fill: #ff8105;
}

.news-search .close-icon {
  position: relative;
  float: right;
  margin-right: 1vw;
  margin-top: 1vh;
}

#news .MuiAvatar-root svg {
  fill: #ff8105;
}

/* pc */
@media screen and (min-width: 600px) {
  .news-search {
    width: 100%;
  }

  #news .bottom-fixed .button {
    width: 20%;
  }
}
