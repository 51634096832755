.box {
  border-top: 1pt solid #c1c1c1;
  border-bottom: 1pt solid #c1c1c1;
  margin-bottom: 2vh;
}

.box-itens .item {
  display: flex;
  padding: 1vh 2vw;
  position: relative;
  height: min-content;
  align-items: center;
}

.box-itens .item:not(:last-child) {
  border-bottom: 1pt solid #c1c1c1;
}

.box-itens .item img {
  max-width: 20%;
  border-radius: 14pt;
  border: 1pt solid #707070;
}

.box-itens .item .infos {
  margin-left: 2vw;
  margin-bottom: 0;
  height: fit-content;
}

.box-itens .item .infos h3 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 10pt;
  color: #9a9a9a;
}

.box-itens .item .infos h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12pt;
  font-weight: 600;
}
.box-itens .item .infos h2 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12pt;
  font-family: Rubik;
  color: #ff8105;
}

.box-itens .item .add-remove {
  display: flex;
  position: absolute;
  bottom: 0.5vh;
  right: 4vw;
}

.box-itens .item .add-remove h3 {
  margin: 0 !important;
  padding: 0 !important;
  font-family: Rubik;
  font-size: 12pt;
}

.box-itens .item .add-remove .remove svg {
  fill: #ff8105;
}
.box-itens .item .add-remove svg {
  cursor: pointer;
}
.box-itens .item .add-remove .add svg {
  fill: #ec662b;
}

.add-more {
  background-color: #ff8105;
}

.gift {
  background-color: #ec662b;
}

#cancelGiftMessage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5px;
}

.gift p,
.add-more p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
}

/* pc */
@media screen and (min-width: 600px) {
  .box-itens .item img {
    max-width: 8%;
  }
}
