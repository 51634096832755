.product {
  border-radius: 20pt 20pt 20pt 20pt;
  box-shadow: 0pt 5pt 10pt #0000001a;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: fit-content;
}
.product.home {
  min-width: 40vw;
}
.product img {
  max-height: 60%;
  max-width: 100%;
  cursor: pointer;
}
.product .favorite {
  cursor: pointer;
}

.product .favorite img {
  position: absolute;
  top: 0px;
  right: 0px;
  margin: 1rem;
  width: 15%;
}

.product .infos p {
  margin: 0;
}
.product .infos {
  padding: 0 2vw;
  margin-top: 1vh;
  position: relative;
}
.product .infos .category {
  color: #9a9a9a;
  font-size: 0.6rem;
}
.product .infos .name {
  color: #ec662b;
  font-size: 1rem;
  width: max-content;
}

.product .infos .name:hover {
  cursor: pointer;
  text-decoration: underline;
}

.product .infos .limited-edition {
  color: #ea4d2c;
  font-size: 0.6rem;
  position: absolute;
  right: 0;
  margin-right: 1vw;
}
.product .infos div {
  display: flex;
  justify-content: space-between;
}
.product .infos div .price {
  font-family: Rubik;
  font-size: 1.2rem;
}
.product .infos div .add svg {
  fill: #ec662b;
  font-size: 1.5rem;
  cursor: pointer;
}
.product .infos div .remove-disabled svg {
  display: none;
}
.product .infos div .remove-enabled svg {
  fill: #ff8105;
  font-size: 1.5rem;
  cursor: pointer;
}

#cartControls {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.product .infos div #quantity {
  font-family: Rubik;
  font-size: 1.5rem;
  margin-left: 5px;
  margin-right: 5px;
}

.ant-notification-notice-btn .ant-btn-primary {
  background-color: #ec662b;
  border-color: #ec662b;
}

.ant-notification-notice-btn .ant-btn-link span {
  color: #ec662b;
}

/* pc */
@media screen and (min-width: 600px) {
  .product .favorite img {
    width: 10%;
  }

  .product.home {
    min-width: 20vw;
  }
}
