#order {
  background-color: #00000034;
  height: 100vh;
}

.welcome-order {
  padding: 4em 2em;
}

#order .content-area {
  position: fixed;
  bottom: 0;
  height: fit-content;
  padding-bottom: 5vh;
  width: 100%;
}
#order .content-area .header {
  margin-bottom: 2vh;
}
#order .content-area .header h3 {
  text-align: center;
}

#order .content-area .infos p {
  text-align: center;
  padding: 0 2rem;
  font-size: 14pt;
  font-weight: 400;
}

#order .MuiInputBase-root {
  width: 90%;
}

#order .MuiInputBase-root svg {
  fill: #1d1d1d;
  margin-right: 1em;
}
