#phone-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vh 2vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

#phone-modal .content {
  width: 100%;
  background-color: white;
  border-radius: 30pt 30pt 30pt 30pt;
  box-shadow: 0pt 3pt 42pt #0000006a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

#phone-modal .content .close {
  align-self: flex-end;
  cursor: pointer;
}
#phone-modal .content .close svg {
  fill: #ff8105;
  font-size: 24pt;
}

#phone-modal .content h3 {
  text-align: center;
  font-weight: 500;
  font-size: 15pt;
  padding: 0vh 2vw;
}
#phone-modal .content .search {
  border-radius: 12pt 12pt 12pt 12pt;
  box-shadow: 0pt 3pt 3pt #00000012;
  align-self: center;
  margin-top: 1vh;
  display: flex;
}

#phone-modal .search input {
  width: 90%;
  padding: 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
  color: black
}

#phone-modal .content .search svg {
  align-self: flex-end;
  margin-bottom: 1.5vh;
  margin-right: 2vw;
}

#phone-modal .button {
  width: 100%;
  align-self: center;
}
/* pc */

@media screen and (min-width: 600px) {
  #phone-modal .content {
    width: 50%;
  }
  #phone-modal .button {
    width: 80%;
  }
  #phone-modal .content .search {
    width: 80%;
    margin-top: 2vh;
  }
  #phone-modal .content .search svg {
    margin-right: 1vw;
  }
}
