#new-password {
  background-color: #00000034;
  height: 100vh;
}

#new-password .content-area .header {
  width: 100%;
}

#new-password .content-area .header h3,
#new-password .content-area h2 {
  text-align: center;
}

#new-password .content-area h4 {
  text-align: center;
  font-size: 1.1em;
  margin-bottom: 10vh;
}

#new-password .content-area h4:hover {
  text-decoration: underline;
  cursor: pointer;
}

.welcome-new {
  padding: 4em 2em;
}
