#failure .header {
  padding: 5vh 4vw 0 4vw;
  color: #1d1d1d;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 1vh;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 2;
  top: 0;
  background-color: white;
}

#failure .welcome-msg {
  color: #1d1d1d;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 4vw;
  margin-bottom: 1vh;
}
#failure .controls {
  cursor: pointer;
}

#failure .header img {
  width: 13%;
  filter: drop-shadow(0pt 3pt 6pt #0000003e);
  margin-left: 1vw;
}

#failure .header .user {
  color: #1d1d1d;
  text-decoration: underline;
}

#failure .bottom {
  bottom: 0;
  width: 100%;
  background-color: white;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 2;
}

#failure .content {
  overflow: scroll;
}
#failure .desc-price {
  padding: 2vh 4vw;
  border-bottom: 0.5pt solid #9a9a9a;
  margin-top: 2vh;
}
#failure .desc-price div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#failure .desc-price div p {
  color: #000000;
  margin: 0;
  font-size: 13pt;
  font-weight: 600;
}

#failure .desc-price div p.number {
  font-family: Rubik;
}

#failure .item.refused {
  border: 2pt solid #ea4d2c;
}

#failure .item.refused:hover {
  cursor: default;
}

#failure .total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2vh 4vw;
  margin-top: 2vh;
}
#failure .total p {
  color: #000000;
  margin: 0;
  font-size: 16pt;
  font-weight: 600;
}

#failure .total p.number {
  font-family: Rubik;
}

#failure .button {
  margin-left: 5vw;
}

#failure .new-card-container {
  width: 100%;
}

#failure .option {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ffffff;
  margin: 0.5em;
}
/* pc */

@media screen and (min-width: 600px) {
  #failure .header img {
    width: 4%;
    margin-left: 0vw;
  }

  #failure .header {
    font-size: 1.4rem;
  }
  #failure .option {
    width: 90%;
  }
  #failure .new-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  #failure #address-form {
    width: inherit;
  }
}
