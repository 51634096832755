#profile-addresses .add-more {
  background-color: #ea4d2c;
}

#profile-addresses .header .MuiAvatar-root:hover {
  cursor: pointer;
}

#profile-addresses.payment .content {
  max-height: 60vh;
}
#profile-addresses .add-more p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
}

#profile-addresses .option {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ffffff;
  margin: 2vh 1em;
}

#profile-addresses.address .bottom {
  border-top: 0.5pt solid #9a9a9a;
  position: relative;
  margin-top: 4vh;
  position: absolute;
  bottom: 0;
  padding-bottom: 4vh;
  background-color: white;
}

#profile-addresses.address .bottom .desc-price {
  border-bottom: none;
  padding: 0 4vw;
}
#profile-addresses.address .bottom .total {
  margin-top: 1vh;
  padding: 0 4vw;
}

#profile-addresses .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 5vh 4vw 0 4vw;
  width: 100%;
}

#profile-addresses .controls {
  cursor: pointer;
}

#profile-addresses.payment.profile .bottom {
  position: absolute;
  bottom: 0;
  height: fit-content;
  width: 100%;
  padding-bottom: 4vh;
}

#profile-addresses .content {
  max-height: 50vh;
  overflow: scroll;
}
#profile-addresses .desc-price {
  padding: 2vh 4vw;
  border-bottom: 0.5pt solid #9a9a9a;
  margin-top: 2vh;
  background-color: white;
}
#profile-addresses .desc-price div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#profile-addresses .desc-price div p {
  color: #000000;
  margin: 0;
  font-size: 13pt;
  font-weight: 600;
}

#profile-addresses .desc-price div p.number {
  font-family: Rubik;
}

#profile-addresses .total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2vh 4vw;
  margin-top: 2vh;
}
#profile-addresses .total p {
  color: #000000;
  margin: 0;
  font-size: 16pt;
  font-weight: 600;
}

#profile-addresses .total p.number {
  font-family: Rubik;
}

#profile-addresses .button {
  margin-left: 5vw;
}

#profile-addresses .new-address-container {
  width: 100%;
}

/* pc */
@media screen and (min-width: 600px) {
  #profile-addresses .new-address-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2vw;
  }
  #profile-addresses #address-form {
    width: inherit;
  }
  #profile-addresses .option {
    width: 100%;
  }
}
