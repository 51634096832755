.box-order {
  box-shadow: 0pt 3pt 6pt #00000029;
  border-radius: 20pt;
  border: 1pt solid #9a9a9a;
  display: flex;
  padding: 1em;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
  justify-content: space-between;
  align-items: center;
}

.box-order-open {
  box-shadow: 0pt 3pt 6pt #00000029;
  border-radius: 20pt;
  border: 1pt solid #9a9a9a;
  margin: 1vh 0;
  align-items: center;
}

.box-order .expand,
.box-order-open .expand {
  cursor: pointer;
}

.box-order .metadata div,
.box-order-open .header div {
  display: flex;
  height: fit-content;
}
.box-order-open .header {
  border-bottom: 1pt solid #9a9a9a;
  margin: 0 !important;
  padding: 1em !important;
  align-items: center;
}

.box-order-open .footer {
  border-top: 1pt solid #9a9a9a;
  margin: 0 !important;
  padding: 1em !important;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.box-order-open .footer p {
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  font-weight: 600;
  color: #1d1d1d;
}

.box-order-open .footer p:hover {
  cursor: pointer;
  text-decoration: underline;
}

.box-order .metadata div p,
.box-order-open .metadata div p {
  padding: 0;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

.box-order p {
  font-weight: 400;
  color: #1d1d1d;
  font-size: 10pt;
  margin: 0;
  margin-top: 0.3vh;
}

.box-order .metadata div .name,
.box-order-open .metadata div .name {
  color: #1d1d1d;
  margin-right: 1vw;
}

.box-order svg,
.box-order-open svg {
  fill: #9a9a9a;
  font-size: 28pt;
}

.box-order .number,
.box-order-open .number {
  color: #ff8105;
  font-family: Rubik;
}
