#profile {
  background-color: #00000034;
  height: 100vh;
}
.welcome-profile {
  padding: 4em 2em;
}

#profile .content-area {
  margin-top: 25vh;
  padding-bottom: 2vh;
  height: fit-content;
}
#profile .content-area .header {
  width: 100%;
}
#profile .content-area h3 {
  text-align: center;
  color: #1d1d1d;
  font-weight: 600;
  font-size: 18pt;
  margin-top: 12vh;
}

#profile .content-area .field {
  margin-bottom: 2vh;
}
#profile .content-area .button {
  margin-bottom: 0.5vh;
  height: 6vh;
}

.button.first {
  background-color: #ff8105;
}
.button.second {
  background-color: #ec662b;
}
.button.third {
  background-color: #ea4d2c;
}
#profile .feedback {
  border-top: 0.1pt solid #9a9a9a;
  width: 100%;
  margin: 2vh 0;
  padding-top: 1vh;
}

#profile .feedback p {
  color: #9a9a9a;
  text-align: center;
}

#profile .feedback p:hover {
  cursor: pointer;
  text-decoration: underline;
}

/* pc */

@media screen and (min-width: 600px) {
  #profile .header img {
    width: 4%;
  }
}
