.post {
  padding: 6vh 5vw 5vh 5vw;
  position: relative;
  border-bottom: 1pt solid #ef8834;
}

.post p {
  color: #9a9a9a;
  margin-bottom: 1vh;
  font-size: 1.2em;
}

.post h1 {
  color: #ff8105;
  font-size: 26pt;
  margin: 0;
}

.post h2 {
  color: #ea4d2c;
  margin-top: 0.5vh;
  margin-bottom: 3vh;
  font-size: 16pt;
}

.post div,
.post div p {
  color: #000000;
  text-align: left;
  font-size: 12pt;
  margin: 0;
}

.post .read-more {
  color: #ff8105;
  text-align: center;
  margin-top: 4vh;
}

.post .read-more:hover {
  text-decoration: underline;
  cursor: pointer;
}

.post div img {
  max-width: 100%;
  height: auto;
  border-radius: 10pt;
  border: 3pt solid #ffffff;
  box-shadow: 0pt 3pt 6pt #00000029;
  margin: 2vh 0;
}

.post .ref {
  width: 100%;
  height: 5vh;
  margin-bottom: 5vh;
  position: relative;
  bottom: 0;
}

.post svg {
  position: absolute;
  margin-left: .5vw;
  fill: #ff8105;
}

/* pc */
@media screen and (min-width: 600px) {
  .post div img {
    max-width: 40%;
  }
}
