#finish-order {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vh 2vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
}

#finish-order .content {
  width: 100%;
  background-color: white;
  border-radius: 30pt 30pt 30pt 30pt;
  box-shadow: 0pt 3pt 42pt #0000006a;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

#finish-order .content .close {
  align-self: flex-end;
  cursor: pointer;
}
#finish-order .content .close svg {
  fill: #ff8105;
  font-size: 24pt;
}

#finish-order .content h3 {
  text-align: center;
  font-weight: 500;
  font-size: 15pt;
  padding: 0vh 2vw;
}
#finish-order .content .search {
  border-radius: 12pt 12pt 12pt 12pt;
  box-shadow: 0pt 3pt 3pt #00000012;
  align-self: center;
  margin-top: 1vh;
  display: flex;
  align-items: center;
}

#finish-order .content .search svg {
  align-self: flex-end;
  margin-right: 2vw;
  margin-bottom: 1.5vh;
}

#finish-order .button {
  width: 100%;
  align-self: center;
}
/* pc */

@media screen and (min-width: 600px) {
  #finish-order .content {
    width: 50%;
  }
  #finish-order .button {
    width: 80%;
  }
  #finish-order .content .search {
    width: 80%;
    margin-top: 2vh;
  }
  #finish-order .content .search svg {
    margin-right: 1vw;
  }
}
