#fidelity {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0vh 2vw;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3000;
  }
  
  #fidelity .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: white;
    border-radius: 30pt 30pt 30pt 30pt;
    box-shadow: 0pt 3pt 42pt #0000006a;
    padding: 1rem;
  }
  
  #fidelity .content .close {
    float: right;
    align-self: flex-end;
    cursor: pointer;
  }
  #fidelity .content .close svg {
    fill: #ff8105;
    font-size: 24pt;
  }
  
  #fidelity .content h3 {
    text-align: center;
    margin-bottom: 2vh;
    font-size: 15pt;
    padding: 0vh 2vw;
  }

  #fidelity .content p {
    text-align: center;
    margin: 2vh 0;
    /* font-size: 15pt; */
    padding: 0vh 2vw;
    color: black
  }
  
  #fidelity .content span {
    color: #ff8105
  }

  #fidelity .content .MuiLinearProgress-root {
    margin: 0vh 6vw;
    height: 2vh;
    border-radius: 30pt;
  }
  
  
  /* pc */
  
  @media screen and (min-width: 600px) {

  }
  