.txt-button:hover {
  cursor: pointer;
}
.txt-button p.active {
  color: #ec662b;
}
.txt-button p.disabled {
  color: #9a9a9a;
}
.txt-button div.active {
  background-color: #ec662b;
}
.txt-button div.disabled {
  background-color: #9a9a9a;
}
