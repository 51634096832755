#signin {
  margin-top: 5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

#signin .fields {
  width: 100%;
  padding-left: 5vw;
}

#signin .fields .field:first-of-type {
  margin-bottom: 3vh;
}
#signin .fields .field {
  width: 93%;
}

.checkbox .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ec662b;
  border-color: #ec662b;
}

.checkbox .ant-checkbox-wrapper .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ec662b;
}

#signin .fields .checkbox-label {
  margin-left: 2vw;
  color: black;
}

.footer {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
  width: 90%;
  justify-content: space-between;
}

.footer .forgot-password {
  color: #ec662b;
  font-weight: 500 !important;
}
.footer .forgot-password:hover {
  cursor: pointer;
  text-decoration: underline;
}

.other-methods {
  width: 100%;
  margin-top: 4vh;
}
.other-methods label {
  color: black;
  font-weight: 500;
}

.methods {
  display: flex;
  margin-top: 2vh;
  width: 60%;
  justify-content: space-evenly;
}

.methods .icon {
  width: 10vw;
}

.methods .icon:hover {
  cursor: pointer;
}

.signin-button {
  background-color: #ec662b;
  margin-top: 2vh;
  border-radius: 20pt 20pt 20pt 20pt;
  border: 1pt solid #ffffff;
}

/* iphone12 */
@media screen and (min-height: 800px) and (max-height: 896px) {
  .methods .icon {
    width: 12vw;
  }
}
/* pc */
@media screen and (min-width: 600px) {
  .methods {
    width: 30%;
  }
  .methods .icon {
    width: 4vw;
  }
}
