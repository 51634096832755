#cart .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 5vh 4vw 0 4vw;
}

#cart .controls {
  cursor: pointer;
}

#cart .header img {
  width: 13%;
  filter: drop-shadow(0pt 3pt 6pt #0000003e);
}

#cart .header img .share {
  filter: drop-shadow(1pt 6pt 10pt #0000003e);
}

#cart .welcome-msg {
  color: #1d1d1d;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 4vw;
  margin-bottom: 2vh;
}

#cart .new-box p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
}

#cart .gift,
#cart .add-more {
  background-color: white;
}

#cart .content {
  max-height: 45vh;
  overflow: scroll;
}

#cart .option {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ec662b;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

#cart .option>*,
#cart .option span svg {
  color: #ec662b;
  fill: #ec662b !important;
}

#cart .option:hover {
  background-color: #ec662b;
}

#cart .option:hover>*,
#cart .option:hover span svg {
  color: white;
  fill: white !important;
}

#cart .option:active>*,
#cart .option:active span svg {
  color: white;
  fill: white !important;
}

#cart .cupom-box {
  height: 5vh;
  border-radius: 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000029;
  color: #1d1d1d;
  background-color: white;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

#cart .cupom-box input {
  width: 90%;
  padding: 0.5em 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#cart .cupom-box .close-icon {
  position: relative;
  float: right;
  margin-right: 2vw;
  margin-top: 1vh;
}

#cart .cupom-box svg {
  fill: #9a9a9a;
}

#cart .desc-price {
  padding: 2vh 4vw;
  border-top: 1pt solid #9a9a9a;
  border-bottom: 1pt solid #9a9a9a;
  background-color: white;
}

#cart .desc-price .discount p {
  color: #ea4d2c;
}

#cart .desc-price div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

#cart .desc-price div p {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

#cart .desc-price div p.number {
  font-family: Rubik;
}

#cart .total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2vh 4vw 0vh 4vw;
  border-top: 1pt solid #c1c1c1;
  margin-top: 2vh;
}

#cart .total p {
  color: #000000;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

#cart .total p.number {
  font-family: Rubik;
}

#buttonFooter {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

/* pc */

@media screen and (min-width: 600px) {
  #cart {
    display: flex;
    flex-direction: column;
  }

  #cart .header img {
    width: 4%;
  }

  #cart .option {
    width: 90%;
    align-self: center;
  }

  #cart .ant-collapse {
    width: 90%;
    align-self: center;
  }

  #cart .ant-collapse .option {
    width: 100%;
    margin: 0;
    margin-bottom: 1vh;
  }
}