#checkout .add-card:hover,
#checkout .add-card.selected {
  background-color: #ea4d2c;
  border: 1pt solid #ffff;
}
#checkout .add-card {
  background-color: white;
  border: 1pt solid #ea4d2c !important;
}

#checkout .add-card:hover > *,
#checkout .add-card:hover span svg,
#checkout .add-card.selected > *,
#checkout .add-card.selected span svg {
  color: white;
  fill: white !important;
}

#checkout .add-card > *,
#checkout .add-card span svg {
  color: #ea4d2c;
  fill: #ea4d2c !important;
}

#checkout .header .MuiAvatar-root:hover {
  cursor: pointer;
}

#checkout.payment .content {
  max-height: 60vh;
}
#checkout .add-card p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
  color: #1d1d1d;
}

#checkout.address .bottom {
  border-top: 0.5pt solid #9a9a9a;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  z-index: 2;
  margin-top: 4vh;
  bottom: 0;
  padding-bottom: 4vh;
  background-color: white;
}

#checkout.address .bottom .desc-price {
  border-bottom: none;
  padding: 0 4vw;
}
#checkout.address .bottom .total {
  margin-top: 1vh;
  padding: 0 4vw;
}

#checkout .cupom-box {
  padding: 0.3vh 4vw;
  border-radius: 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000029;
  color: #1d1d1d;
  background-color: white;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
  display: flex;
  justify-content: space-between;
}

#checkout .cupom-box input {
  width: 90%;
  padding: 1em 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#checkout .cupom-box .check-button {
  width: 30%;
  margin-left: 0;
  border: 1pt solid #ec662b;
  box-shadow: 0pt 4pt 8pt #00000033;
 
}

#checkout .cupom-box .check-button:hover {
  background-color: #ec662b;
}
#checkout .cupom-box .check-button > * {
  color: black;
}
#checkout .cupom-box .check-button:hover > *,
#checkout .cupom-box .check-button:hover span svg {
  color: white;
  fill: white !important;
}
#checkout .cupom-box .check-button:active > *,
#checkout .cupom-box .check-button:active span svg {
  color: white;
  fill: white !important;
}

#checkout .cupom-box .close-icon:hover {
  cursor: pointer;
}

#checkout .cupom-box svg {
  fill: #ea4d2c;
}
#checkout .cupom-title {
  padding: 0 6vw;
  margin: 2vh 0;
}
#checkout .cupom-title h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
}

#checkout .desc-price .discount p {
  color: #ea4d2c;
}

#checkout .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 5vh 4vw 0 4vw;
  width: 100%;
}

#checkout .controls {
  cursor: pointer;
}

#checkout .bottom {
  position: absolute;
  bottom: 0;
  height: fit-content;
  width: 100%;
  background-color: white;
}

#checkout .content {
  max-height: 50vh;
  overflow: scroll;
}
#checkout .desc-price {
  padding: 2vh 4vw;
  border-bottom: 0.5pt solid #9a9a9a;
  margin-top: 2vh;
  background-color: white;
}
#checkout .desc-price div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#checkout .desc-price div p {
  color: #000000;
  margin: 0;
  font-size: 13pt;
  font-weight: 600;
}

#checkout .desc-price div p.number {
  font-family: Rubik;
}

#checkout .total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2vh 4vw;
  margin-top: 2vh;
}
#checkout .total p {
  color: #000000;
  margin: 0;
  font-size: 16pt;
  font-weight: 600;
}

#checkout .total p.number {
  font-family: Rubik;
}

#checkout .button {
  margin-left: 5vw;
}

/* pc */

@media screen and (min-width: 600px) {
  #checkout .content {
    display: flex;
    flex-direction: column;
  }

  #checkout .option {
    width: 90%;
    align-self: center;
  }

  #checkout .ant-collapse {
    width: 90%;
    align-self: center;
  }

  #checkout .ant-collapse .option {
    width: 100%;
    margin: 0;
    margin-bottom: 1vh;
  }

  #checkout .cupom-box .check-button {
    width: 15%;
   height: 6vh;
  }
}
