#signon {
  margin-top: 3vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

#signon .fields {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#signon .fields label {
  color: black;
  font-weight: 500;
}
#signon .fields .ant-input {
  border: none;
  color: #9a9a9a !important;
}

#signon .fields .icon {
  fill: #ec662b;
  margin-right: 2vw;
}

#signon .fields .input {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
}

#signon .fields .line {
  background-color: #9a9a9a;
  width: 100%;
  height: 0.1vh;
  margin-top: 0.2vh;
  margin-bottom: 0.2vh;
}

#signon .fields .field {
  margin-bottom: 1vh;
}
#signon .fields .field {
  width: 93%;
}
#signon .fields .footer {
  display: flex;
  flex-direction: column;
  width: 93%;
}
#signon .fields .footer a {
  color: #ec662b;
}

.checkbox .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ec662b;
  border-color: #ec662b;
}

.checkbox .ant-checkbox-wrapper .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ec662b;
}

.other-options {
  width: 100%;
  margin-top: 2vh;
}
.other-options label {
  color: black;
  font-weight: 500;
  text-align: center;
  font-size: 12pt;
  width: 80%;
}
.other-options .line {
  background-color: #ff8105;
  width: 10%;
  height: 0.5vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
.other-options .button:last-of-type {
  margin-bottom: 4vh;
}

.houseTypes {
  display: flex;
  margin-bottom: 3vh;
}

.houseTypes .chip {
  margin-right: 1vw;
}

#complementaryForm {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#showComplementaryControls {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#showComplementaryControls .button + .button {
  margin-top: 0;
}
