#success {
  background-color: #00000034;
  height: 100vh;
}

#success .explore {
  width: 100%;
  padding-right: 4vw;
}

#success .brand-success {
  margin-top: 8vh;
}

#success .brand-success .content-area .header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 0;
  width: auto;
}

#success .brand-success .content-area .header h3 {
  text-align: center;
}

#success .brand-success .content-area .header h4 {
  text-align: center;
  padding: 0 4vw;
}

#success .brand-success .content-area .infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#success .brand-success .content-area h1 {
  text-align: center;
  font-family: Rubik;
  font-size: 24pt;
  margin: 0;
}

.welcome-success {
  padding: 4em 2em;
}

#order-number {
  text-align: center;
}

#success .content-area .infos + .infos {
  margin-top: 0;
}

#success .content-area .pix {
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
}

#success .content-area .pix h4,
#success .content-area .pix h3 {
  text-align: center;
}

#success .content-area .pix h4 {
  cursor: pointer;
  text-decoration: underline;
}

#success .content-area .pix img {
  max-width: 50%;
}

/* pc */
@media screen and (min-width: 600px) {
  #success .brand-success .content-area .order-info {
    display: flex;
    padding: 4vh 0;
  }

  #success .content-area .order-info {
    justify-content: space-evenly;
  }
  #success .content-area .order-info .order-status {
    max-width: 60%;
  }
}
