.box-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 4vw;
}

.box-header p {
  color: #000000;
  font-weight: 500;
  text-align: right;
}

.box-header p:hover {
  cursor: pointer;
  text-decoration: underline;
}
