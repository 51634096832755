#select-city {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vh 2vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3000;
}

#select-city .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 30pt 30pt 30pt 30pt;
  box-shadow: 0pt 3pt 42pt #0000006a;
  padding: 1rem;
}

#select-city .content .close {
  float: right;
  align-self: flex-end;
  cursor: pointer;
}
#select-city .content .close svg {
  fill: #ff8105;
  font-size: 24pt;
}

#select-city .content h3 {
  text-align: center;
  margin-bottom: 2vh;
  font-size: 15pt;
  padding: 0vh 2vw;
}

#select-city .content h4 {
  text-align: center;
  margin: 2vh 0;
  font-size: 12pt;
  padding: 0vh 2vw;
}

#select-city .button {
  width: 100%;
  align-self: center;
}

#select-city .order-type {
  display: flex;
  margin-top: 2vh;
}

#select-city .order-type .button {
  margin: 0vh 1.5vw 0vh 1.5vh;
  color: white
}

#select-city .MuiInputBase-root svg {
  fill: white;
  margin-right: 1em;
}

#select-city .MuiInputBase-root {
  margin-top: 3vh;
  color: white;
}

/* pc */

@media screen and (min-width: 600px) {
  #cart .header img {
    width: 4%;
  }
  #select-city .content {
    width: 50%;
  }
  #select-city .button {
    /* width: 80%; */
    margin: 2vh 4vw 0vh 4vw;
  }
}
