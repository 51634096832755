.gift-option .ant-collapse-header,
.gift-option .ant-collapse-content-box {
  padding: 0 !important;
}

.gift-option .content {
  margin: 2vh 1em;
}
.gift-option .ant-collapse-expand-icon {
  width: 100%;
}

.gift-option .gift-message-input {
  width: 100%;
  border-radius: 18pt;
  border: 1pt solid #707070;
  padding: 1em;
  color: #1d1d1d;
  margin-top: 1vh;
}

.gift-option .word-count {
  color: #707070;
  text-align: right;
  margin-right: 0.5em;
}

.gift-option input[type="radio"] {
  border: 0px;
  width: 6vw;
  height: 4vh;
  vertical-align: middle;
  accent-color: #ec662b;
  margin-left: 0.5em;
}

.gift-option .content .input {
  display: flex;
  align-items: center;
}

.gift-option .content .input .input {
  margin-right: 2vw;
}

.input.is-gift {
  margin-bottom: 1vh;
}

.gift-option .content .input p {
  color: #1d1d1d;
  margin: 0;
  margin-left: 2vw;
  font-weight: 600;
}

/* pc */

@media screen and (min-width: 600px) {
  .gift-option input[type="radio"] {
    width: 2vw;
  }

  .gift-option .content .input p {
    margin-left: 1vw;
  }

  .gift-option .content .input {
    margin-bottom: 1vh;
  }
}
