#order-date .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 5vh 4vw 0 4vw;
}

#order-date .header .MuiAvatar-root:hover {
  cursor: pointer;
}

#order-date .controls {
  cursor: pointer;
}

#order-date .header img {
  width: 13%;
  filter: drop-shadow(0pt 3pt 6pt #0000003e);
}
#order-date .header img .share {
  filter: drop-shadow(1pt 6pt 10pt #0000003e);
}

#order-date .welcome-msg {
  color: #1d1d1d;
  font-size: 1.4rem;
  font-weight: 500;
  padding: 0 4vw;
  margin: 4vh 0 2vh 0;
}

#order-date .new-box p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
}

#order-date .new-box {
  background-color: #ea4d2c;
}

#order-date .content {
  max-height: 45vh;
  overflow: scroll;
}

#order-date .option {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ffffff;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

#order-date .cupom-box {
  height: 5vh;
  border-radius: 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000029;
  color: #1d1d1d;
  background-color: white;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

#order-date .cupom-box input {
  width: 90%;
  padding: 0.5em 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#order-date .cupom-box .close-icon {
  position: relative;
  float: right;
  margin-right: 2vw;
  margin-top: 1vh;
}

#order-date .cupom-box svg {
  fill: #9a9a9a;
}
#order-date .desc-price {
  padding: 2vh 4vw;
  border-top: 1pt solid #9a9a9a;
  border-bottom: 1pt solid #9a9a9a;
  background-color: white;
}
#order-date .desc-price .discount p {
  color: #ea4d2c;
}
#order-date .desc-price div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
#order-date .desc-price div p {
  color: #000000;
  margin: 0;
  font-size: 1rem;
  font-weight: 600;
}

#order-date .desc-price div p.number {
  font-family: Rubik;
}

#order-date .total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 2vh 4vw 0vh 4vw;
  border-top: 1pt solid #c1c1c1;
  margin-top: 2vh;
}
#order-date .total p {
  color: #000000;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 600;
}

#order-date .total p.number {
  font-family: Rubik;
}

#order-date .buttonFooter {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.react-calendar {
  background-color: #fff;
  color: #222;
  line-height: 1.125em;
  padding: 0 4vw;
  border: none;
  width: 100%;
}

/* setinha */
.react-calendar__navigation button {
  color: #ff8105;
  font-size: 1.2rem;
}

.react-calendar__navigation__label {
  text-align: left;
}

/*  titulo  */
.react-calendar__navigation__label span {
  color: #ff8105;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: white;
  color: #c1c1c1;
}

.react-calendar__navigation button[disabled] {
  display: none;
}

/* header */
abbr[title] {
  text-decoration: none;
  color: black;
}

/* dias */
abbr {
  color: black;
}

.react-calendar__tile:disabled {
  background-color: white;
}

/* disabled dias */
.react-calendar__tile:disabled abbr {
  color: #c1c1c1;
}

/* selecionado */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus,
.react-calendar__month-view__days__day:hover,
.react-calendar__month-view__days__day:focus {
  background: #f8f8fa;
  color: black;
  border-radius: 6px;
}
.react-calendar__tile--now {
  background: white;
  color: black;
}

.react-calendar__tile--now:disabled:hover,
.react-calendar__tile--now:disabled:focus {
  background: white;
  color: black;
}

/* selecionado */
.react-calendar__tile--active {
  border-radius: 6px;
  background: white;
  border: 1px solid black !important;
}

/* selecioando */
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: white;
  border: 1px solid black;
}

.hourSelect {
  width: 90%;
}

.hourSelect svg {
  fill: black;
  margin-right: 1vw;
}

/* pc */

@media screen and (min-width: 600px) {
  #order-date .header img {
    width: 4%;
  }
}
