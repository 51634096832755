.order-closed {
    box-shadow: 0pt 3pt 6pt #00000029;
    border-radius: 20pt;
    border: 1pt solid #9a9a9a;
    display: flex;
    padding: 1em;
    margin-bottom: 1vh;
    margin-right: 1em;
    margin-left: 1em;
    justify-content: space-between;
    align-items: center;
  }
  
  .order-open {
    box-shadow: 0pt 3pt 6pt #00000029;
    border-radius: 20pt;
    border: 1pt solid #9a9a9a;
    margin-bottom: 1vh;
    margin-right: 1em;
    margin-left: 1em;
    align-items: center;
  }
  
  .order-open .boxes {
    padding: 2em
  }

  .order-open .boxes .info {
    color: black;
    margin:0
  }

  .order-open .boxes .desc-price div {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .order-open .boxes .desc-price div p {
    color: #000000;
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .order-open .boxes .desc-price div p.number {
    font-family: Rubik;
  }

  .order-closed .expand,
  .order-open .expand {
    cursor: pointer;
  }
  
  .order-closed .metadata div,
  .order-open .header div {
    display: flex;
    height: fit-content;
  }
  .order-open .header {
    border-bottom: 1pt solid #9a9a9a;
    margin: 0 !important;
    padding: 1em !important;
    align-items: center;
  }
  
  .order-open .footer {
    border-top: 1pt solid #9a9a9a;
    margin: 0 !important;
    padding: 1em !important;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  
  .order-open .footer p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: #1d1d1d;
  }
  
  .order-open .footer p:hover {
    cursor: pointer;
    text-decoration: underline;
  }
  
  .order-closed .metadata div p,
  .order-open .metadata div p {
    padding: 0;
    margin: 0;
    font-size: .6rem;
    font-weight: 600;
  }
  
  .order-closed p {
    font-weight: 400;
    color: #1d1d1d;
    font-size: 10pt;
    margin: 0;
    margin-top: 0.3vh;
  }
  
  .order-closed .metadata div .name,
  .order-open .metadata div .name {
    color: #1d1d1d;
    margin-right: 1vw;
  }
  
  .order-closed svg,
  .order-open svg {
    fill: #9a9a9a;
    font-size: 20pt;
  }
  
  .order-closed .number,
  .order-open .number {
    color: #ff8105;
    font-family: Rubik;
  }
  
  @media screen and (min-width: 600px) {
    .order-closed .metadata div p,
    .order-open .metadata div p {
      font-size: .8rem;
    }
  }
  