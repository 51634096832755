.payment-methods {
  width: 100%;
  margin-top: 2vh;
}
.payment-methods .title {
  display: flex;
  padding: 1vh 6vw;
  justify-content: space-between;
}
.payment-methods .title svg {
  fill: #1d1d1d;
}
.payment-methods .title h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
}

.payment-methods .item {
  display: flex;
  justify-content: space-between;
  padding: 1vh 4vw;
  border: 1pt solid #9a9a9a;
  background-color: #ffffff;
  border-radius: 20pt;
  margin: 0.5em;
  cursor: pointer;
  align-items: center;
}

.payment-methods .item.selected {
  border: 2pt solid white;
  background-color: #ec662b;
}

.payment-methods .item.selected .infos h1,
.payment-methods .item.selected .infos h3 {
  color: white;
}

.payment-methods .item .infos {
  margin-left: 2vw;
  margin-bottom: 0;
}

.payment-methods .item .actions div svg {
  fill: #ff8105;
}

.payment-methods .item .actions div svg {
  fill: #ff8105;
}

.payment-methods .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh auto;
}

.payment-methods .item .actions div:hover {
  cursor: pointer;
}

.payment-methods .item .infos h3 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 9pt;
  color: #1d1d1d;
}

.payment-methods .item .infos h1 {
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
  margin: 0.5vh 0;
}

.payment-methods .item {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ec662b;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

.payment-methods .item:hover {
  background-color: #ec662b;
  border: 1pt solid white;
}

.payment-methods .item:hover h1,
.payment-methods .item:hover h3 {
  color: white;
}

#addCard:hover {
  cursor: pointer;
}

/* pc */

@media screen and (min-width: 600px) {
  .payment-methods {
    display: flex;
    flex-direction: column;
  }
  .payment-methods .item,
  #checkout .cupom-box {
    width: 90%;
    align-self: center;
  }
}
