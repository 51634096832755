#welcome {
  height: 101vh;
  background-color: #ff8105;
  padding: 4em 1em;
}

.message-area {
  width: 100%;
  background-image: linear-gradient(123deg, #ffffff65, #ffffff0d);
  backdrop-filter: blur(40pt);
  margin-top: 30vh;
  margin-bottom: 1vh;
}

.message-area h3 {
  font-size: 20pt;
  margin-top: 2vh;
  margin-bottom: 2vh;
  color: white;
}
.message-area .message p {
  font-size: 12pt;
  text-align: center;
  margin: 0;
  /* margin-bottom: 1vh; */
}

.message-area .message {
  margin-bottom: 2vh;
}

.iconMsg {
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
}

.iconMsg svg {
  margin-top: 0.1vh;
}

.iconMsg p {
  display: inline-block;
  font-size: 1.2em;
}

.message-area .button {
  margin-bottom: 2vh;
}

.posts {
  position: relative;
}

.sticky-button {
  position: fixed;
  width: 90%;
  top: 85vh;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}

.normal-button {
  position: fixed;
  width: 90%;
  top: 75vh;
  z-index: 999;
  left: 50%;
  transform: translateX(-50%);
}
#welcome .welcome {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* pc */
@media screen and (min-width: 600px) {
  .message-area {
    margin-top: 45vh;
  }
  #welcome {
    padding: 4em 2em;
    display: flex;
    flex-direction: column;
  }
  #welcome .welcome #explore-btn {
    width: 55%;
  }
  .message-area .button {
    width: 70%;
  }
  .message-area .message {
    margin-bottom: 1vh;
  }
  .message-area {
    width: 30%;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
  }
  #welcome .welcome {
    margin-top: 4vh;
  }

  .normal-button,
  .sticky-button {
    width: 30%;
  }
}
