#closed {
  background-color: #00000034;
  height: 100vh;
}

#closed .explore {
  width: 100%;
  padding-right: 4vw;
}

#closed .brand-closed {
  margin-top: 8vh;
}

#closed .brand-closed .content-area .header {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
}

#closed .brand-closed .content-area .header h3 {
  text-align: center;
  width: 80%;
}
#closed .brand-closed .content-area .infos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2vh;
}
#closed .brand-closed .content-area .infos h3 {
  text-align: center;
}

#closed .brand-closed .content-area h1 {
  text-align: center;
  font-family: Rubik;
  font-size: 24pt;
}
