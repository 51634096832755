.content-login .content-area .header-login div + div {
  margin-left: 2vw;
  margin-right: 2vw;
}

.content-login .content-area .header-login div p {
  font-size: 16pt;
  margin: 0;
  margin-bottom: 0.8vh;
}

.detail {
  width: 6vw;
  height: 0.6vh;
}

.btn:hover,
.clickable:hover {
  cursor: pointer;
}
