@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@400;500;600&family=Rubik:wght@600&display=swap");
html {
  scroll-behavior: smooth;
  margin: 0;
  height: 100%;
}
body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

option {
  color: black !important;
  background-color: transparent !important;
}

.imageBg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.MuiAvatar-root svg {
  fill: #ff8105;
}

.MuiAvatar-root {
  background-color: white !important;
  box-shadow: 0pt 3pt 6pt #00000029;
}

.MuiCircularProgress-root {
  -webkit-animation: animation-61bdi0 1.4s linear infinite !important;
  animation: animation-61bdi0 1.4s linear infinite !important;
}

* {
  font-family: Lexend;
  outline: none !important;

  /* hide scrollbar */
  /* -ms-overflow-style: none; 
  scrollbar-width: none;  */
}

*:not(.ant-notification-notice):not(.imageBg):not(.boxNotification):not(
    #orderInfoButton
  ):not(#toolTip) {
  /* fade-in */
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 1s; /* Firefox < 16 */
  -ms-animation: fadein 1s; /* Internet Explorer */
  -o-animation: fadein 1s; /* Opera < 12.1 */
  animation: fadein 1s;
}

.fadeout {
  opacity: 0;
  width: 0;
  height: 0;
  /* fade-out */
  -webkit-animation: fadeout 1s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadeout 1s; /* Firefox < 16 */
  -ms-animation: fadeout 1s; /* Internet Explorer */
  -o-animation: fadeout 1s; /* Opera < 12.1 */
  animation: fadeout 1s !important;
}

.fadeout > * {
  opacity: 0;
  width: 0;
  height: 0;
}

/* hide scrollbar */
*::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.common {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.rounded {
  border-radius: 30pt 30pt 30pt 30pt;
  border: 1pt solid white;
}

.bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.btn {
  width: 90%;
  height: 8vh;
  box-shadow: 0pt 4pt 8pt #00000034;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* fadeout */

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}

.icon-explore {
  fill: #1d1d1d !important;
}
