#checkout .add-more-address {
  background-color: #ea4d2c;
  color: white;
}

#checkout .add-more-address p {
  margin: 0;
  margin-left: 2vw;
  width: 90%;
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

#checkout .option {
  display: flex;
  padding: 2vh 4vw;
  box-shadow: 0pt 4pt 8pt #00000033;
  align-items: center;
  cursor: pointer;
  border-radius: 25pt;
  border: 1pt solid #ffffff;
  margin: 2vh 1em;
}

#checkout.address .bottom {
  border-top: 0.5pt solid #9a9a9a;
  position: relative;
  margin-top: 4vh;
}

#checkout.address .bottom .desc-price {
  border-bottom: none;
  padding: 0 4vw;
}
#checkout.address .bottom .total {
  margin-top: 1vh;
  padding: 0 4vw;
}
