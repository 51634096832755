#products {
  padding: 5vh 4vw 0 4vw;
}

#products .header {
  top: 0;
  padding: 2rem 1rem 2rem 0;
  display: flex;
  align-items: center;
  background-color: white;
  justify-content: space-between;
}

#products .header object {
  width: 30px;
}

#products .header .icon {
  display: flex;
  align-items: center;
}

#products .header .icon object {
  pointer-events: none;
}

#products .header .icon:hover {
  cursor: pointer;
}

#products .header .explore p {
  display: inline-block;
  color: #000000;
  margin: 0;
  font-weight: 500;
  font-size: 13pt;
}

#products .header .explore:hover {
  cursor: pointer;
  text-decoration: underline;
}

#products .welcome-msg {
  color: #1d1d1d;
  font-size: 1.4rem;
  font-weight: 500;
}

#products .user {
  color: #000000;
  text-decoration: underline;
  font-weight: 500;
  font-size: 12pt;
  margin: 0;
  margin-left: 2vw;
}
#products .delivery-type {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}
#products .delivery-type .select {
  width: 48%;
  box-shadow: 2pt 4pt 12pt #1d1d1d21;
  border-radius: 15pt 15pt 15pt 15pt;
  padding: 0.8rem;
}

#orderInfoButtonHighlighted p {
  margin: 0 !important;
}
#products #orderInfoButton:hover,
#orderInfoButtonHighlighted:hover {
  cursor: pointer;
}

#products .delivery-type .select.selected {
  background-color: #ec662b;
  z-index: 3;
}
#products .delivery-type .select.disabled {
  background-color: #ff8105;
}

#products .delivery-type .select .more {
  float: right;
}

#products .search {
  width: 100%;
  height: 6vh;
  margin-top: 4vh;
  border-radius: 16pt 16pt 16pt 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000014;
  color: #1d1d1d;
}
#products .search input {
  width: 90%;
  padding: 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#products .search svg {
  fill: #9a9a9a;
  position: relative;
  float: right;
  margin-right: 2vw;
  margin-top: 1.5vh;
}

#products .categories::-webkit-scrollbar {
  display: none;
}

#products .categories {
  display: flex;
  width: 100%;
  overflow: auto;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  margin-top: 1vh;
}

#products .categories .category {
  border: 0;
  background-color: #ff8105;
  color: white;
  padding: 0 2vw;
  margin-right: 2vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

#products .categories .category.selected {
  background-color: #ea4d2c;
}

#products .categories .category p {
  color: white;
  width: max-content;
}
#products .products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1rem;
  max-height: 100%;
  margin-top: 2vh;
  margin-bottom: 8vh;
}

#products .fixed {
  position: fixed;
  z-index: 1;
  bottom: 0;
  margin-bottom: 2vh;
  margin-left: 1vw;
}

#products .btn-space {
  height: 5vh;
}
.empty {
  margin: 18vh auto;
}
.empty svg {
  fill: #ec662b;
  font-size: 5em;
}

.empty p {
  color: #1d1d1d;
}
/* pc */
@media screen and (min-width: 600px) {
  #products .products {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  #products .user {
    margin-left: 1vw;
  }
}

.ant-notification-notice-close svg {
  fill: #1d1d1d;
}

.ant-notification-notice-description {
  color: #1d1d1d;
}

#products .blocker.enabled {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.6));
  z-index: 2;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

#products .blocker.disabled {
  display: none;
}

#products #orderInfoButton,
#orderInfoButtonHighlighted {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  color: white
}
#products #orderInfoButton:hover,
#orderInfoButtonHighlighted:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}


@keyframes mymove {
  50% {
    box-shadow: 0px 0px 40px 10px white;
  }
}
