#forgot-password {
  background-color: #00000034;
  height: 100vh;
}

#forgot-password .brand-forgot {
  padding-top: 14vh;
}

.welcome-forgot {
  padding: 4em 2em;
}

.brand-forgot .content-area .infos p {
  text-align: justify;
  padding: 0 2rem;
}

#forgot-password .content-area .infos p {
  text-align: center;
}
