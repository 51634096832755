#product {
  height: 100vh;
  overflow-y: hidden;
}

#product img {
  width: 100%;
}

#product .options {
  position: absolute;
  top: 0;
  left: 0;
  padding: 4em 1.5em;
  width: fit-content;
  z-index: 2;
}
#product .options div {
  width: 12vw;
}
#product .options div:hover {
  cursor: pointer;
}
#product .options img {
  width: 100%;
  margin-bottom: 2vh;
  filter: drop-shadow(1pt 6pt 10pt #0000003e);
}
#product .content-area {
  z-index: 100;
  box-shadow: 0pt -7pt 28pt #00000029;
  border-radius: 30pt 30pt 0 0;
  padding-bottom: 0;
}

#product .content-area .infos {
  margin-top: 3vh;
  padding: 0vh 6vw;
  width: 100%;
  margin-bottom: 0;
}
#product .content-area .infos h4 {
  color: #1d1d1d;
  font-weight: 600;
  margin: 0;
}
#product .content-area .infos .limited-edition {
  color: #ea4d2c;
  text-align: end;
  font-weight: 600;
}
#product .content-area .infos .ingredients {
  margin-bottom: 1vh;
}
#product .content-area .infos h1 {
  color: #ff8105;
  font-size: 26pt;
  font-weight: 600;
}
#product .content-area .infos p {
  font-weight: 100;
  font-size: 10pt;
}

#product .content-area .price {
  display: flex;
  border-top: 0.5pt solid #9a9a9a;
  justify-content: space-between;
  margin-top: 2vh;
  margin-bottom: 0;
}
#product .content-area .infos .value {
  margin-top: 2vh;
}
#product .content-area .infos .value p:last-child {
  font-family: Rubik;
  font-size: 20pt;
}
#product .content-area .infos p {
  color: #1d1d1d;
}
#product .content-area .infos .add-remove {
  display: flex;
  width: 40%;
  justify-content: space-evenly;
  margin-top: 6vh;
}
#product .content-area .infos .add-remove .icon svg {
  fill: #ff8105;
}
#product .content-area .infos .add-remove h3 {
  font-family: Rubik;
  font-size: 18pt;
}

#product .content-area .infos .add-remove svg {
  font-size: 24pt;
}
#product .content-area .infos .add-remove .add svg {
  fill: #ea4d2c;
}

#product .content-area .infos .add-remove div {
  cursor: pointer;
}
#product .content-area .infos .add-remove .iconDisabled {
  cursor: default;
  color: white
}

#product
  [data-theme="compact"]
  .site-collapse-custom-collapse
  .site-collapse-custom-panel,
#product .site-collapse-custom-collapse .site-collapse-custom-panel {
  background: white;
}

#product
  .ant-collapse
  > .ant-collapse-item
  > .ant-collapse-header
  .ant-collapse-arrow
  svg {
  fill: #1d1d1d;
}

#product .search {
  width: 100%;
  height: 6vh;
  margin-top: 4vh;
  border-radius: 16pt 16pt 16pt 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000014;
  color: #1d1d1d;
}
#product .search input {
  width: 90%;
  padding: 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#product .search svg {
  fill: #9a9a9a;
  position: relative;
  float: right;
  margin-right: 2vw;
  margin-top: 1.5vh;
}

#product .ant-row {
  height: 60%;
}

#product .seeMore {
  display: flex;
  justify-content: center;
  align-items: center;
}

#product .seeMore p {
  margin: 0;
  font-weight: 600 !important;
  font-size: 10pt !important;
  margin-left: 1vw;
}

#product .ant-collapse-header {
  padding: 0;
}

#product .esgotado {
  color: #ff8105;
  height: fit-content;
  position: absolute;
  bottom: 3vh;
  right: 4vw;
  font-family: Rubik;
  font-size: 16pt;
}

/* pc */

@media screen and (min-width: 600px) {
  #product .options {
    padding: 4em 6em;
  }
  #product .content-area .infos .add-remove {
    width: 10%;
  }
  #product .options div {
    width: fit-content;
  }
  #product .options img {
    width: 3vw;
    margin-bottom: 2vh;
    filter: drop-shadow(1pt 6pt 10pt #0000003e);
  }
  #product .content-area .price {
    margin-bottom: 6vh;
  }
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}

.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}


.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: rgba(255, 255, 255, 0.87);
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}

.carousel-control-prev:hover,
.carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: rgba(255, 255, 255, 0.87);
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.87%29'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='rgba%28255, 255, 255, 0.87%29'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}

.carousel-indicators > .active {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color:  #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

.carousel-indicators .active {
  opacity: 1;
}