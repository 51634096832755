#stores .header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 2;
  justify-content: space-between;
}

#stores .header object {
  width: 25px;
}

#stores .header .icon {
  display: flex;
  align-items: center;
}

#stores .header .icon object {
  pointer-events: none;
}

#stores .header .icon:hover {
  cursor: pointer;
}

#stores .header .explore p {
  display: inline-block;
  color: #000000;
  margin: 0;
  font-weight: 500;
  font-size: 13pt;
}

#stores .header .explore:hover {
  cursor: pointer;
}

#stores .MuiAvatar-root svg, #stores .MuiSvgIcon-root {
  fill: #ff8105;
}

#stores .content {
  padding: 0 3vw 6vh 3vw;
}

#stores .content .store .img {
  background-color: #ff8105;
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#stores .content .store .infos {
  padding: 1em 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#stores .content .store .infos .address {
  text-align: center;
}

#stores .content .store .infos p {
  font-weight: 400;
  font-size: 1em;
  margin: 0 !important;
}

#stores .content .store .infos a {
  font-weight: 400;
  font-size: 1em;
  margin: 0 !important;
  color: black;
  text-decoration: underline;
  display: flex;
}
#stores .content .store .infos a svg {
  margin-right: .5vw;
}



#stores .content .store .infos div {
  margin: 2vh 0 !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* pc */
@media screen and (min-width: 800px) {
  #stores .content {
    padding: 0 3vw 6vh 3vw;
    display: grid;
    gap: 1em;
    grid-template-columns: minmax(50%, 1fr) 2fr;
    grid-template-rows: auto auto auto;
    height: 100%;
    position: relative;
  }
    #stores .content .store .infos .address {
      max-width: 30%;
    }
    #stores .content .store .infos a {
      font-weight: 400;
      font-size: 1em;
      margin: 0 !important;
      color: black;
      text-decoration: none;
    }
  
    #stores .content .store .infos a:hover {
      text-decoration: underline;
    }
}
