#orders .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vh;
  padding: 5vh 8vw 0 8vw;
}

#orders .header div {
  font-size: 1.2rem;
}

#orders .header div:hover {
  cursor: pointer;
}

#orders .header .explore {
  color: #ec662b;
  font-weight: 500;
  margin: 0;
  padding: 0;
}

#orders .orders {
  padding: 0 6vw;
  margin-top: 4vh;
}

#orders .orders .order {
  margin-bottom: 4vh;
}

#orders .orders .order h4 {
  font-weight: 600;
  margin-bottom: 2vh;
}


#orders .box-order.active {
  border: 2pt solid #ff8105;
}
/* pc */

@media screen and (min-width: 600px) {
}
