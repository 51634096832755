#explore-btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0.5em 2em 0.5em 1.5em;
  border-radius: 20pt;
  height: 8vh;
  border: 1pt solid #ffffff99;
  background-image: linear-gradient(103deg, #ffffff65, #ffffff0d);
  filter: blur(40);
  -webkit-filter: blur(40);
  align-items: center;
}

#explore-btn p {
  font-size: 12pt;
  font-weight: 500;
  margin: 0 !important;
  color: white;
}

#explore-btn p:hover {
  cursor: pointer;
}

#explore-btn object {
  width: 25px;
  pointer-events: none;
}

#explore-btn div:hover {
  cursor: pointer;
}

#explore-btn .MuiAvatar-root {
  background-color: white;
  box-shadow: 0pt 3pt 6pt #00000029;
}

#explore-btn .MuiAvatar-root:hover {
  cursor: pointer;
}

#explore-btn .MuiAvatar-root svg {
  fill: #ff8105;
}

/* pc */
@media screen and (min-width: 600px) {
  #brand {
    width: 15vw;
  }
}
