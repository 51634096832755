.chip {
  margin-top: 2vh;
  border-radius: 10pt 10pt 10pt 10pt;
  width: max-content;
}
.chip.selected {
  background-color: #ff8105;
}
.chip.unselected {
  border: 1pt solid black;
}
.chip:hover {
  cursor: pointer;
}
.chip.unselected p {
  color: black;
}
.chip p {
  font-size: 12pt;
  font-weight: 500;
  padding: 0.8vh;
  margin: 0;
  color: white
}
