#login {
  background-color: #00000034;
  height: 50vh;
}

.welcome-login {
  padding: 4em 2em;
}

.suggestion {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 6vh;
}

.suggestion h4 {
  width: 60%;
  text-align: end;
  margin-right: 6vw;
  color: white;
}
.suggestion .icon {
  margin-right: 8vw;
}

.content-area {
  position: absolute;
  bottom: 0;
  padding-bottom: 5vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  background-color: #ffffff;
  border-radius: 40pt 40pt 0 0;
  max-height: 80vh;
  overflow-y: scroll;
}

.content-area .header {
  margin-top: 3vh;
  margin-bottom: 6vh;
}

.header-login {
  display: flex;
  flex-direction: row;
  width: 95%;
  border-radius: 40pt 40pt 0 0;
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: white;
  justify-content: center;
  padding-top: 3vh;
}

.header h3 {
  color: black;
  font-size: 20pt;
}

.arrowBack {
  fill: #ec662b !important;
  font-size: 24pt !important;
  position: absolute;
  left: 6vw;
}
.arrowBack:hover {
  cursor: pointer;
}
#login .infos {
  margin-bottom: 2vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#login .infos p {
  color: black;
  margin: 0;
  font-weight: 400;
  font-size: 12pt;
  margin-bottom: 1vh;
}
.infos {
  margin-bottom: 2vh;
}
.infos p {
  color: black;
  margin: 0;
  font-weight: 550;
  font-size: 12pt;
  margin-bottom: 1vh;
}

#login .infos p:first-child {
  font-weight: 500;
}
