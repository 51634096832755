#terms .header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  padding: 2rem 1rem;
  display: flex;
  align-items: center;
  background-color: white;
  z-index: 2;
  justify-content: space-between;
}

#terms .header object {
  width: 25px;
}

#terms .header .icon {
  display: flex;
  align-items: center;
}

#terms .header .icon object {
  pointer-events: none;
}

#terms .header .icon:hover {
  cursor: pointer;
}

#terms .header .explore p {
  display: inline-block;
  color: #000000;
  margin: 0;
  font-weight: 500;
  font-size: 13pt;
}

#terms .header .explore:hover {
  cursor: pointer;
  text-decoration: underline;
}

#terms .MuiAvatar-root svg {
  fill: #ff8105;
}

#terms .content {
  padding: 0 6vw 6vh 6vw;
  display: flex;
  flex-direction: column;
}

#terms .content h1 {
  color: #ff8105;
  margin-bottom: 4vh;
}

#terms .content h2 {
  color: #ff8105;
  margin-bottom: 2vh;
}

#terms .content p {
  color: black;
  text-align: justify;
}

#terms .content .about {
  background-image: url("../../images/sobre-nos.jpg");
  display: flex;
  justify-self: center;
  align-self: center;
  max-height: 60vh;
  margin-bottom: 6vh;
}
/* pc */
@media screen and (min-width: 600px) {
}
