#home {
  padding: 5vh 6vw 0 6vw;
}
#home .header {
  display: flex;
  justify-content: space-between;
}

#home .header div {
  font-size: 1.2rem;
}

#home .header .explore .avatar:hover,
#home .header .explore .icon {
  cursor: pointer;
}

#home .header .explore .icon object {
  pointer-events: none;
}

#home .avatar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 2vh 0;
}

#home .user {
  color: #000000;
  text-decoration: underline;
  font-weight: 500;
  font-size: 12pt;
  margin: 0;
  margin-left: 2vw;
}

#home .avatar .MuiAvatar-root {
  width: 8vw;
  height: 8vw;
}

#home .header .explore {
  color: #000000;
  font-weight: 500;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#home .header .explore object {
  width: 25px;
}

#home .header .explore .icon {
  display: flex;
  align-items: center;
}

#home .welcome-msg {
  color: #1d1d1d;
  font-size: 1.2rem;
  font-weight: 500;
}

#home .search {
  width: 100%;
  height: 6vh;
  margin: 2vh 0;
  border-radius: 16pt;
  box-sizing: border-box;
  border: 0.5px solid #9a9a9a;
  box-shadow: inset 0pt 3pt 6pt #00000014;
  color: #1d1d1d;
}
#home .search input {
  width: 90%;
  padding: 1em !important;
  margin: 0em !important;
  border: none;
  background-color: transparent;
  outline: none;
}

#home .search svg {
  fill: #9a9a9a;
  position: relative;
  float: right;
  margin-right: 2vw;
  margin-top: 1.5vh;
}

#home .products {
  display: flex;
  width: 100%;
  overflow: scroll;
  /* -ms-overflow-style: none;
  scrollbar-width: none; */
  margin-top: 1vh;
  padding: 1em;
}

#home .products .product {
  margin-right: 2vw;
}

#home .button {
  width: 100%;
}
.empty {
  margin: 18vh auto;
}
.empty svg {
  fill: #ec662b;
  font-size: 5em;
}

.empty p {
  color: #1d1d1d;
}
/* pc */
@media screen and (min-width: 600px) {
  #home .avatar .MuiAvatar-root {
    width: 2vw;
    height: 2vw;
  }
  #home .user {
    margin-left: 1vw;
  }
  #home .header .explore object {
    width: 35px;
  }

  #home .products::-webkit-scrollbar:horizontal {
    display: block;
    height: 1vh;
    border: 1px solid #d5d5d5;
    border-radius: 16pt;
  }

  #home .products::-webkit-scrollbar-thumb:horizontal {
    background: #8f8e8e;
    opacity: 10%;
    border-radius: 16pt;
  }
}

.ant-notification-notice-close svg {
  fill: #1d1d1d;
}

.ant-notification-notice-description {
  color: #1d1d1d;
}

#home #orderInfoButtonHighlighted {
  z-index: 3;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: fadein 1s, mymove 5s infinite;
}

#home #orderInfoButtonHighlighted:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

#home #orderInfoButton {
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
#home #orderInfoButton:hover {
  -webkit-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

@keyframes mymove {
  50% {
    box-shadow: 0px 0px 40px 10px white;
  }
}
