.cart-closed {
    box-shadow: 0pt 3pt 6pt #00000029;
    border-radius: 25pt;
    border: 1pt solid #9a9a9a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1vh 6vw;
    padding: 2vh 4vw;

}

.cart-open {
    box-shadow: 0pt 3pt 6pt #00000029;
    border-radius: 25pt;
    border: 1pt solid #9a9a9a;
    align-items: center;
    margin: 1vh 6vw;
    padding: 2vh 4vw;

}

.cart-open .boxes {
    padding: 2em
}

.cart-open .boxes .info {
    color: black;
    margin: 0
}

.cart-closed .expand,
.cart-open .expand {
    cursor: pointer;
}

.cart-closed .metadata div,
.cart-open .header div {
    display: flex;
    height: fit-content;
}

.cart-open .header {
    border-bottom: 1pt solid #9a9a9a;
    margin: 0 !important;
    padding: 1em !important;
    align-items: center;
}

.cart-open .footer {
    border-top: 1pt solid #9a9a9a;
    margin: 0 !important;
    padding: 1em !important;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.cart-open .footer p {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    font-weight: 600;
    color: #1d1d1d;
}

.cart-open .footer p:hover {
    cursor: pointer;
    text-decoration: underline;
}

.cart-closed .metadata div p,
.cart-open .metadata div p {
    padding: 0;
    margin: 0;
    font-size: .6rem;
    font-weight: 600;
}

.cart-closed p {
    font-weight: 400;
    color: #1d1d1d;
    font-size: 10pt;
    margin: 0;
    margin-top: 0.3vh;
}

.cart-closed .metadata div .name,
.cart-open .metadata div .name {
    color: #1d1d1d;
    margin-right: 1vw;
    font-size: 13pt;
    font-weight: 600;
}

.cart-closed svg,
.cart-open svg {
    fill: #9a9a9a;
    font-size: 20pt;
}

.cart-closed .number,
.cart-open .number {
    color: #ff8105;
    font-family: Rubik;
}

@media screen and (min-width: 600px) {

    .cart-closed .metadata div p,
    .cart-open .metadata div p {
        font-size: .8rem;
    }
}