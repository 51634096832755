.brand {
  width: 2.5em;
  height: 2.5em;
}

.checkbox .ant-checkbox-checked .ant-checkbox-inner,
.ant-checkbox-indeterminate .ant-checkbox-inner {
  background-color: #ec662b;
  border-color: #ec662b;
}

.checkbox .ant-checkbox-wrapper .ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ec662b;
}

.checkbox-container {
  margin-top: 1vh;
}

.checkbox-container .checkbox-label {
  margin-left: 2vw;
  color: black;
}
