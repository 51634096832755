.field {
  width: 93%;
}
.field label {
  color: black;
  font-weight: 500;
}
.field .ant-input,
.field .ant-input-password {
  border: none;
  color: #9a9a9a !important;
}

.field .icon {
  fill: #ec662b;
  margin-right: 2vw;
}

.field .ant-input-password-icon svg {
  fill: #ec662b;
}

.field .input {
  display: flex;
  flex-direction: row;
  margin-top: 1vh;
}

.field .line {
  width: 100%;
  height: 0.1vh;
  margin-top: 0.2vh;
  margin-bottom: 0.2vh;
}

.field input.ant-input:focus,
.field input.ant-input-password:focus {
  border-color: transparent;
  outline: none;
}
