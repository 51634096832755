#order-date-page {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0vh 2vw;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 300000;
}

#order-date-page .content {
  width: 100%;
  background-color: white;
  border-radius: 30pt 30pt 30pt 30pt;
  box-shadow: 0pt 3pt 42pt #0000006a;
  padding: 1rem;
}

#order-date-page .content .close {
  float: right;
  cursor: pointer;
}
#order-date-page .content .close svg {
  fill: #ff8105;
  font-size: 24pt;
}

#order-date-page .content h3 {
  text-align: center;
  color: #ec662b;
  font-size: 20pt;
}
#order-date-page .content .search {
  border-radius: 12pt 12pt 12pt 12pt;
  box-shadow: 0pt 3pt 3pt #00000012;
}

#order-date-page .content h4 {
  text-align: center;
  margin-top: 3vh;
  font-weight: 500;
  font-size: 14pt;
}

#order-date-page .content h5 {
  text-align: center;
  font-weight: 500;
  font-size: 14pt;
}
#order-date-page .content h6 {
  text-align: center;
}

#order-date-page .content .delivery-type .select {
  box-shadow: 0pt 3pt 6pt #00000029;
}
#order-date-page .content .delivery-type .select p {
  font-size: 16pt;
  text-align: center;
  margin-bottom: 0;
}

#order-date-page .content .delivery-type .disabled {
  border: 1pt solid #9a9a9a;
}

#order-date-page .delivery-type {
  display: flex;
  justify-content: space-between;
  margin-top: 2vh;
}
#order-date-page .delivery-type .select {
  width: 48%;
  box-shadow: 2pt 4pt 12pt #1d1d1d21;
  border-radius: 15pt 15pt 15pt 15pt;
  padding: 0.8rem;
}

#order-date-page .delivery-type .select:hover {
  cursor: pointer;
}

#order-date-page .delivery-type .select.selected {
  background-color: #ec662b;
}
#order-date-page .delivery-type .select .more {
  float: right;
}
#order-date-page .delivery-type .disabled svg {
  fill: #9a9a9a;
}
#order-date-page .delivery-type .disabled p {
  color: #9a9a9a;
}

#order-date-page .days {
  display: flex;
  width: 100%;
  overflow: auto;
}

#order-date-page .days::-webkit-scrollbar {
  display: none;
}

#order-date-page .days .day {
  border: 0.5pt solid #9a9a9a;
  border-radius: 13pt;
  width: 100%;
  padding: 1rem 1.6rem;
  cursor: pointer;
  margin-right: 1.5vw;
}

#order-date-page .days .day p {
  color: #ff8105;
  text-align: center;
  margin-bottom: 0;
}

#order-date-page .days .day h5 {
  font-family: Rubik;
  font-size: 16pt;
  text-align: center;
  color: #ff8105;
  margin-bottom: 0;
}

#order-date-page .hours {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.1rem;
  margin-top: 2vh;
}

#order-date-page .hours .hour {
  border: 0.5pt solid #9a9a9a;
  border-radius: 12pt;
  width: 100%;
  padding: 0.5rem;
  cursor: pointer;
  margin-top: 1vh;
}

#order-date-page .hours .hour.selected,
#order-date-page .days .day.selected {
  background-color: rgb(240, 240, 240);
}

#order-date-page .hours .hour p {
  color: #ff8105;
  text-align: center;
  margin-bottom: 0;
}

#order-date-page h5 {
  font-size: 10pt;
  text-align: center;
  margin-top: 2vh;
}

#order-date-page .button {
  width: 100%;
}
