.delivery-adressess {
  width: 100%;
  padding: 0 2vw;
  margin-top: 1vh;
}

.delivery-adressess .title {
  display: flex;
  justify-content: space-between;
  padding: 1vh 4vw;
}

.delivery-adressess .title svg {
  fill: #1d1d1d;
}

.delivery-adressess .title h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
}

.delivery-adressess .item {
  display: flex;
  justify-content: space-between;
  padding: 1vh 4vw;
  border: 1pt solid #9a9a9a;
  background-color: #ffffff;
  border-radius: 20pt;
  margin: 0.5em;
  cursor: pointer;
}

.item.selected {
  border: 2pt solid #ff8105;
}

.delivery-adressess .item .infos {
  margin-left: 2vw;
  margin-bottom: 0;
}

.delivery-adressess .item .actions div svg {
  fill: #ff8105;
}

.delivery-adressess .item .infos h3 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 9pt;
  color: #1d1d1d;
}

.delivery-adressess .item .infos h1 {
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
  margin: 0.5vh 0;
}
.delivery-adressess .item .radio {
  align-self: center;
}

.delivery-adressess .item .radio input[type="radio"] {
  border: 0px;
  width: 6vw;
  height: 4vh;
  vertical-align: middle;
  accent-color: #ec662b;
}

.delivery-adressess .empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2vh auto;
}
