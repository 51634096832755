.fields {
  padding: 0 4vw;
}

.fields .title {
  display: flex;
  justify-content: space-between;
  padding: 1vh 4vw;
}

.fields .title svg {
  fill: #1d1d1d;
}

.fields .title h1 {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 13pt;
  font-weight: 600;
  color: #1d1d1d;
}

.addressChip {
  margin-right: 5px;
  margin-left: 5px;
}

#address-form.fields .field {
  width: 100%;
}

.houseTypes {
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: scroll;
}

.fields label {
  color: black;
}

.other-options .line {
  background-color: #ff8105;
  width: 10%;
  height: 0.5vh;
  margin-top: 3vh;
  margin-bottom: 3vh;
}
