#order-login .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2vh;
    padding: 5vh 4vw 0 4vw;
}

#order-login .header .MuiAvatar-root:hover {
    cursor: pointer;
}

#order-login .controls {
    cursor: pointer;
}

#order-login .header img {
    width: 13%;
    filter: drop-shadow(0pt 3pt 6pt #0000003e);
}

#order-login .header img .share {
    filter: drop-shadow(1pt 6pt 10pt #0000003e);
}

#order-login .content {
padding: 0 4vw;
}

#order-login .welcome-msg {
    color: #1d1d1d;
    font-size: 1.4rem;
    font-weight: 500;
    margin: 4vh 0 2vh 0;
}

#order-login .content .first-order-container {
    margin-top: 2vh
}

#order-login .content .first-order-container p {
    margin: 0
}

#order-login .content .first-order-container .first-order-title {
    color: #ea4d2c
}

#order-login .content .first-order-container .field {
    margin: 2vh 0;
}


/* pc */

@media screen and (min-width: 600px) {
    #order-login .header img {
        width: 4%;
    }
}