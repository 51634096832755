#explore {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

#explore .close {
  float: right;
  margin: 2vh 2vw;
}

#explore .close .MuiAvatar-root svg {
  fill: #ec662b;
}

#explore .close:hover {
  cursor: pointer;
}

#explore .modal {
  background-color: #ec662b;
}

#explore .secondary {
  margin-bottom: 65vh;
  z-index: 8;
}

#explore .primary {
  margin-top: 1vh;
  box-shadow: 0pt 0pt 15pt #00000040;
  z-index: 9;
  height: 70vh;
  align-items: center;
}

#explore .primary .option {
  display: flex;
  width: 70%;
  margin-top: 0.5vh;
  margin-bottom: 0;
  height: fit-content;
  justify-content: center;
}

#explore .primary .footer h5 {
  color: #1d1d1d;
  font-size: 12pt;
  margin: none !important;
  font-weight: 100;
  text-align: center;
}

#explore .primary .footer h5:hover {
  cursor: pointer;
  text-decoration: underline;
}

#explore .primary .footer {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 4vh;
  margin-bottom: 0;
  height: fit-content;
  justify-content: center;
  border-top: 0.5pt solid #9a9a9a;
  padding-top: 4vh;
}

#explore .primary .option h4:hover {
  cursor: pointer;
}

#explore .primary .option:first-child {
  margin-top: 4vh;
}

#explore .primary .option h4 {
  color: black;
  font-size: 16pt;
  margin: none !important;
  font-weight: 100;
}
#explore .primary .option .icon {
  fill: #ec662b;
  margin-right: 4vw;
}
#explore .secondary .title {
  display: flex;
  margin-top: 2vh;
}

#explore .secondary .title:hover {
  cursor: pointer;
}

#explore .secondary .title h4 {
  color: white;
  font-size: 14pt;
}

/* iphone12 */
@media screen and (min-height: 800px) and (max-height: 896px) {
}

/* pc */

@media screen and (min-width: 600px) {
  #explore .close {
    width: 5%;
  }

  #explore .secondary {
    margin-bottom: 66vh;
  }
}
