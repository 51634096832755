.box-multiple {
  box-shadow: 0pt 3pt 6pt #00000029;
  border-radius: 20pt;
  border: 1pt solid #9a9a9a;
  display: flex;
  padding: 1em;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
  justify-content: space-between;
}

.box-multiple-open {
  box-shadow: 0pt 3pt 6pt #00000029;
  border-radius: 20pt;
  border: 1pt solid #9a9a9a;
  margin-bottom: 1vh;
  margin-right: 1em;
  margin-left: 1em;
}

.box-multiple-open .header .delete-box,
.box-multiple .delete-box {
  color: #000000;
  text-align: right;
  font-size: 1em;
}

.box-multiple-open .header .delete-box:hover,
.box-multiple .delete-box:hover {
  cursor: pointer;
  text-decoration: underline;
}

.box-multiple .expand,
.box-multiple-open .expand {
  cursor: pointer;
}

.box-multiple div,
.box-multiple-open .header div {
  display: flex;
}
.box-multiple-open .header {
  border-bottom: 1pt solid #9a9a9a;
  margin: 0 !important;
  padding: 1em !important;
}
.box-multiple p,
.box-multiple-open p {
  padding: 0;
  margin: 0;
  font-size: 1rem;
}

.box-multiple .name,
.box-multiple-open .name {
  color: #1d1d1d;
  margin-right: 1vw;
}

.box-multiple svg,
.box-multiple-open svg {
  fill: #ff8105;
}

.box-multiple .number,
.box-multiple-open .number {
  color: #ff8105;
  font-family: Rubik;
}

/* pc */

@media screen and (min-width: 600px) {
  .box-multiple-open .header .delete-box,
  .box-multiple .delete-box {
    width: 80%;
  }

  .box-multiple,
  .box-multiple-open {
    width: 90%;
    align-self: center;
  }
}
